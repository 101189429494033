import Service from "@/services/service";

export default class UserService extends Service {
  constructor() {
    super("users");
  }

  create(user, options) {
    return this.post("", user, options);
  }

  read(id, options) {
    return this.get(`/${id}`, options);
  }

  update(id, user, options) {
    return this.patch(`/${id}`, user, options);
  }

  remove(id, options) {
    return this.delete(`/${id}`, options);
  }

  list(options) {
    return this.get("", options);
  }

  listOrganizations(uid, options) {
    return this.get(`/${uid}/organizations`, options);
  }

  joinOrganization(relation, options) {
    const { user, organization, role } = relation;
    return this.post(`${user}/organizations/${organization}`, { role }, options);
  }

  changeRoleInOrganization(relation, options) {
    const { user, organization, role } = relation;
    return this.patch(`${user}/organizations/${organization}`, { role }, options);
  }

  leaveOrganization(relation, options) {
    const { user, organization } = relation;
    return this.delete(`${user}/organizations/${organization}`, options);
  }
}
