import Service from "@/services/service";
import { merge } from "lodash";

export default class PlanService extends Service {
  constructor() {
    super("plans");
  }

  read(id, options) {
    return this.get(`/${id}`, options);
  }

  update(id, plan, options) {
    return this.patch(`/${id}`, plan, options);
  }

  create(plan, options) {
    return this.post("", plan, options);
  }

  listStudies(id, options) {
    return this.get(`/${id}/studies`, options);
  }

  listArtifacts(id, options) {
    return this.get(`/${id}/artifacts`, options);
  }

  uploadArtifact(id, formData, options) {
    options = merge(options, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return this.post(`/${id}/artifacts`, formData, options);
  }

  downloadArtifact(id, aid, options) {
    return this.get(`/${id}/artifacts/${aid}/download`, options);
  }
}