import { defineStore } from "pinia";

export const useMainStore = defineStore({
  id: "main",
  state: () => ({
    users: [],
    modules: [],
    organizations: []
  })
});
