import { h } from "vue";
import { createRouter, createWebHistory, RouterView } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import OrganizationsView from "@/views/OrganizationsView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [{
    path: "/",
    redirect: {
      name: "organizations"
    }
  }, {
    path: "/users",
    component: { render: () => h(RouterView) },
    children: [{
      path: "",
      name: "users",
      component: () => import("@/views/UsersView.vue"),
      beforeEnter: authGuard
    }, {
      path: ":id",
      name: "user",
      component: () => import("@/views/UserView.vue"),
      beforeEnter: authGuard
    }, {
      path: "create",
      name: "createUser",
      component: () => import("@/views/CreateUserView.vue"),
      beforeEnter: authGuard
    }]
  }, {
    path: "/organizations",
    component: { render: () => h(RouterView) },
    children: [{
      path: "",
      name: "organizations",
      component: OrganizationsView,
      beforeEnter: authGuard
    }, {
      path: ":id",
      name: "organization",
      component: () => import("@/views/OrganizationView.vue"),
      beforeEnter: authGuard
    }, {
      path: ":id/studies/:sid",
      name: "study",
      component: () => import("@/views/StudyView.vue"),
      beforeEnter: authGuard
    }, {
      path: ":id/plans/:pid",
      name: "plan",
      component: () => import("@/views/PlanView.vue"),
      beforeEnter: authGuard
    }, {
      path: ":id/plans/create",
      name: "createPlan",
      component: () => import("@/views/CreatePlanView.vue"),
      beforeEnter: authGuard
    }, {
      path: "create",
      name: "createOrganization",
      component: () => import("@/views/CreateOrganizationView.vue"),
      beforeEnter: authGuard
    }]
    }, {
    path: "/modules",
    component: { render: () => h(RouterView) },
    children: [{
      path: "",
      name: "modules",
      component: () => import("@/views/ModulesView.vue"),
      beforeEnter: authGuard
    }, {
      path: ":id",
      name: "module",
      component: () => import("@/views/ModuleView.vue"),
      beforeEnter: authGuard
    }]
  }, {
    path: "/callback",
    name: "callback",
    component: () => import("@/views/CallbackView.vue")
  }, {
    path: "/logout",
    redirect: "/"
  }]
});

export default router;
