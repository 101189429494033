<template>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
    <div class="min-w-1/4 text-white p-3 rounded-lg fixed left-52 bottom-4" :class="`bg-${alertStore.color}`" v-show="show">
      <p>{{ alertStore.message }}</p>
    </div>
  </transition>
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useAlertStore } from "@/stores/alert";

const alertStore = useAlertStore();
const show = ref(false);

const { message } = storeToRefs(alertStore);
let handler = null;

watch(message, () => {
  if (alertStore.message.length) {
    show.value = true;
    if (handler) {
      clearTimeout(handler);
    }
    handler = setTimeout(() => {
      show.value = false;
      alertStore.reset();
    }, alertStore.timeout);
  }
});
</script>