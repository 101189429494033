import UserService from "./users";
import OrganizationService from "./organizations";
import PlanService from "./plans";
import StudyService from "./studies";
import FeatureService from "./features";
import ModuleService from "./modules";

export const UsersService = new UserService();
export const OrganizationsService = new OrganizationService();
export const PlansService = new PlanService();
export const StudiesService = new StudyService();
export const FeaturesService = new FeatureService();
export const ModulesService = new ModuleService();
