import Service from "@/services/service";

export default class OrganizationService extends Service {
  constructor() {
    super("organizations");
  }

  create(organization, options) {
    return this.post("", organization, options);
  }

  read(id, options) {
    return this.get(`/${id}`, options);
  }

  update(id, organization, options) {
    return this.patch(`/${id}`, organization, options);
  }

  remove(id, options) {
    return this.delete(`/${id}`, options);
  }

  list(options) {
    return this.get("", options);
  }

  listUsers(id, options) {
    return this.get(`/${id}/users`, options);
  }

  listStudies(id, options) {
    return this.get(`/${id}/studies`, options);
  }

  listPlans(id, options) {
    return this.get(`/${id}/plans`, options);
  }

  attachStudy(id, sid, months, options) {
    return this.post(`/${id}/studies/system/${sid}`, months, options);
  }

  detachStudy(id, sid, options) {
    return this.delete(`/${id}/studies/system/${sid}`, options);
  }
}
