<template>
  <header class="px-8 py-4 w-full flex justify-between">
    <h2 class="text-3xl dark:text-zinc-50">Organizations</h2>
    <RouterLink class="flex text-zinc-50 space-x-2 items-center px-3 py-2 rounded-lg border border-accent bg-accent/75 hover:bg-accent text-sm"
      to="organizations/create">
      <IconOrganizationsPlus class="w-5 h-5" />
      <span>Create Organization</span>
    </RouterLink>
  </header>
  <section class="h-[calc(100%-68px)]">
    <div class="px-8 py-4 grid grid-cols-4 gap-x-4 items-center">
      <SearchInput v-model="organizationFilter" />
    </div>
    <div class="h-[calc(100%-70px)] px-8">
      <div class="flex h-full w-full place-items-center justify-center" v-if="pending">
        <IconSpinner class="animate-spin w-12 h-12" />
      </div>
      <ul class="rounded overflow-hidden divide-y divide-zinc-200 dark:divide-zinc-600" v-else>
        <OrganizationListItem v-for="organization in filteredOrganizations" :organization="organization" :key="organization.id" />
      </ul>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from "vue";
import { RouterLink } from "vue-router";
import { isEmpty, sortBy } from "lodash";
import IconOrganizationsPlus from "@/components/icons/IconOrganizationsPlus.vue";
import IconSpinner from "@/components/icons/IconSpinner.vue";
import SearchInput from "@/components/SearchInput.vue";
import OrganizationListItem from "@/components/OrganizationListItem.vue";
import { useMainStore } from "@/stores/main";

const mainStore = useMainStore();
const pending = ref(false);
const organizationFilter = ref("");
const filteredOrganizations = computed(() => sortBy(mainStore.organizations.filter(({ name }) => isEmpty(organizationFilter.value) || name.toLowerCase().includes(organizationFilter.value.toLowerCase())), ["name", "created"]));
</script>
