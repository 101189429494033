import { merge } from "lodash";
import Service from "@/services/service";

export default class GeometryService extends Service {
  constructor() {
    super("features");
  }

  read(id, options) {
    return this.get(`/${id}`, options);
  }

  processFile(formData, options) {
    options = merge(options, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return this.post("", formData, options);
  }
}
