import Service from "@/services/service";

export default class ModuleService extends Service {
  constructor() {
    super("modules");
  }

  read(id, options) {
    return this.get(`/${id}`, options);
  }

  update(id, mod, options) {
    return this.patch(`/${id}`, mod, options);
  }

  list(options) {
    return this.get("", options);
  }
}
