import { defineStore } from "pinia";
import { get, nth } from "lodash";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    user: {},
    organizations: [],
    studies: {}
  }),
  getters: {
    userName: (state) => state.user.name,
    userEmail: (state) => state.user.email,
    userId: (state) => nth(get(state, "user.sub", "|").split('|'), 1),
    isAdmin: (state) => (state.user['http://airsage.com/roles'] || []).includes('Jacquerie Admin')
  }
});
